// Variable overrides first
$primary: #313fb3;
$accent: #2242ac;
// $primary: #FFFFFF;
$secondary: #4f5ddb;
// $accent: #FFFFFF;
$base: #120309;
$background: #e7f5f2;
$border: rgba(73, 190, 170, 0.1);
$border-radius: 0.25rem;

// Cambiar la variable de la fuente general a comic sans cursiva
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace;
$body-color: $base;

@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/noty/src/noty.scss";
@import "../../node_modules/noty/src/themes/relax.scss";
@import "./modal.scss";
@import "./table.scss";
@import "./card.scss";
@import "./form.scss";
@import "./cms.scss";
@import "./filter.scss";
@import "./navbar.scss";
@import "./inputs.scss";
// @import '../modules/beeldit/documental-system/scss/documental-system.scss';
@import url("https://fonts.googleapis.com/css2?family=Hanken+Grotesk:wght@400;900&display=swap");

:root {
  --sidebar-width: 260px;
  --navbar-height: 100px;
  --bs-blue: var(--bs-primary);
  --bs-letter-spacing: 0.06rem;
  --bs-letter-spacing: var(--bs-letter-spacing);
  --bs-body-color: #4d545a;  
}


@media (min-width: 1200px) {
  // :root {
  // TODO no entiendo porque entre 1200 y 1400 mide 80 el margin
  // --navbar-height: 80px;
  // }
  .navbar-brand img {
    width: 184px !important;
  }
}

@media (min-width: 1400px) {
  :root {
    --navbar-height: 100px;
  }

  .navbar-brand img {
    width: 184px !important;
  }
}

#admin-panel {
  background-color: var(--background, #e7f5f2);
}

.admin {
  .page-content {
    background-color: var(--background, #e7f5f2);
    min-height: 100vh;
    padding: 2rem;
    position: relative;
  }

  @media (min-width: 992px) {
    width: calc(
      100vw - var(--sidebar-width) - 15px
    ); // no se de donde vien ees 15px
    margin-left: 260px;
  }
}

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  background-color: #fff;
  width: var(--sidebar-width);
  height: 100%;
}

.nav-link:hover {
  color: var(--bs-link-hover-color) !important;
}

.hover-translate:hover {
  transition: transform 0.1s ease-out;
  transform: translateX(3px);
}

.click:hover {
  cursor: pointer;
}

.sidebar .nav-item:last-child {
  position: absolute;
  bottom: var(--navbar-height);
  width: 100%;
}

/**
*  Start Filters styles
*/
.filters-container > .form-row > .property-wrapper {
  display: inline-flex;
  align-items: left;
  margin-left: 1rem;
  margin-bottom: 0.5rem;
}

/**
*  End Filters styles
*/
.navbar .navbar-brand {
  margin-left: 1.5rem;
}

.card-header button.icon-button:not(:last-child) {
  margin-right: 1rem;
}

.modal-title,
.btn-close {
  color: white;
}

body {
  font-family: var(--bs-body-font-family) !important;
}

.nav-tabs {
  border: none !important;
}

.nav-item button {
  background-color: white;
}

.sidebar .nav-link.active {
  background-color: var(--bs-blue);
  border: none;
  color: var(--bs-white) !important;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.kpy {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

@media (max-width: 992px) {
  .kpy {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: none;
  }

  .col-span-2 {
    grid-column: span 1 / span 1;
  }
}

.kpy-item {
  border-radius: 0.5rem;
  background: rgba(231, 245, 242, 0.5);
}

.kpy-content {
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-content: space-evenly;
  align-items: flex-start;
}

.kpy-icon {
  margin-bottom: 1rem;
  color: $secondary;
}

.kpy-title {
  color: var(--bs-blue);
  font-weight: bold;
  font-size: 2.5rem;
}

.kpy-description {
  color: var(--bs-black);
  opacity: 0.75;
}

.button-action {
  width: 40px !important;
  height: 40px !important;
}

.text-right {
  text-align: right;
}

.error-detail li {
  list-style-type: none;
}

.error-detail {
  margin-top: 0.5rem;
  padding-left: 0px;
}

.btn-action {
  border-color: #fff;
  background-color: #fff;
  color: var(--accent, #f2542d);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
}

.btn-action:hover {
  background-color: #327a58;
}

td.boolean,
th.boolean {
  text-align: center;
}

.w-95 {
  width: 95% !important;
}

.field-description {
  font-size: 0.75rem;
}

/* Auth */
.auth-panel {
  font-family: "Hanken Grotesk", sans-serif;

  .card {
    border-radius: 0;

    .card-body {
      margin: 2rem 5rem;

      .card-logo {
        width: 100%;
        text-align: center;
        margin-bottom: 2rem;

        img {
          width: 250px;
        }
      }
    }
  }

  button {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    background-color: var(--bs-black);
    border: none;
    height: 56px;
    border-radius: 0;

    &:hover {
      background-color: var(--bs-black);
    }
  }

  a {
    color: var(--bs-black);

    &:hover {
      color: var(--bs-black);
    }
  }

  input {
    border-radius: 0;
    height: 56px;
    border: 1px solid var(--bs-black);

    &:focus {
      border-color: var(--bs-black);
      box-shadow: none;
    }
  }
}

@media (max-width: 768px) {
  .auth-panel {
    font-family: "Hanken Grotesk", sans-serif;

    .card {
      width: 100%;
    }
  }
}

p {
  color: #000000;
}

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Rediseño 01/24
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.45rem 1rem;
}

.btn-primary {
  color: #fff;
  background-color: $accent;
  border-color: $accent;

  &:hover {
    background-color: darken($accent, 10%);
    border-color: darken($accent, 10%);
  }
}

.table .button-action {
  padding: 0.25rem;
  background-color: transparent;
  border-color: transparent;
  color: $accent;

  &:hover {
    background-color: $accent;
    border-color: $accent;
    color: #fff;
  }
}

.action-button-text {
  font-size: 1rem !important;
  font-weight: 500 !important;
  color: #fff !important;
  margin-left: 0px !important;
  @media (max-width: 768px) {
    display: none !important;
  }
}

// Forms
.form-control,
.css-1ulxeju-control {
  border-radius: var(--bs-border-radius);
  background-color: $background;
  border-color: $background;
  color: $base;

  &:hover {
    border-color: hsl(0, 0%, 70%);
  }

  &::placeholder {
    opacity: 0.5;
  }
}

.css-1i2ta08-control,
.css-1i2ta08-control:hover,
.css-1i2ta08-control:active {
  border-radius: var(--bs-border-radius);
  color: $base;
  background-color: $background;
  border-color: hsl(0, 0%, 70%);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(79, 103, 91, 0.25);
}

.css-1u9des2-indicatorSeparator {
  display: none;
}

.css-1jqq78o-placeholder,
.css-1dimb5e-singleValue {
  text-align: left;
  color: $base;
}

.css-tj5bde-Svg {
  fill: $base;
  stroke: $base;
}

.css-1nmdiq5-menu {
  text-align: left;
}

.admin-navbar {
  .navbar-brand {
    img {
      filter: drop-shadow(0px 100px 0 $primary);
    }
  }
}
@media (min-width: 992px) {
  .admin-navbar {
    display: none !important;
  }
}
@media (max-width: 992px) {
  #admin-panel {
    .page-content {
      margin-top: 100px;
    }
  }
}
// Admin panel
.admin {
  // Filters
  .filters-container {
    padding: 0;
    margin: 0;
  }

  // Sidebar
  .sidebar {
    padding: 0 !important;

    @media (min-width: 992px) {
      display: flex !important;
    }

    .navbar-brand {
      overflow: hidden;
      display: flex;
      padding: 1.5rem 2rem;

      img {
        filter: drop-shadow(0px 100px 0 $primary);
      }
    }

    .nav-item {
      padding-right: 1rem;
      margin-bottom: 0.85rem;

      &:last-child {
        position: relative;
        bottom: unset;
        margin-top: auto;
      }
    }

    .nav-link {
      border-radius: 0 1.5rem 1.5rem 0;
      padding: 0.75rem 1rem 0.75rem 2rem;
      margin-left: -0.5rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      svg {
        opacity: 0.75;
      }
    }
  }

  // Cards
  .card-header {
    padding: 1.5rem;
    border-radius: 0.75rem 0.75rem 0 0;
    border-color: $background;

    span {
      color: $base;
      font-size: 1.5rem;
      text-transform: initial;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: normal;
      display: inline-block;
      margin-left: 1rem;
    }
  }

  // Tables
  .table-responsive {
    margin: 0 -1.5rem;
    border-top: 1px solid $border;
  }

  .table > :not(caption) > * > * {
    padding: 0.25rem 0.75rem;
    border-color: $border;
  }

  .table th {
    padding: 0.75rem 0.75rem;
  }

  .table thead th:first-child,
  .table tbody td:first-child {
    width: 3rem;
  }

  .table .number {
    text-align: right;
  }

  .table .action-column {
    padding-right: 1.5rem;
  }

  .pagination {
    margin: 0;

    & + br {
      display: none;
    }
  }

  // Tabs
  .nav-tabs {
    position: relative;
    z-index: 2;

    .nav-link {
      border: none;
      border-radius: 0.75rem 0.75rem 0 0;
      padding: 1.25rem 2rem;
      font-weight: 500;
      margin-right: 2px;
      background-color: #f3faf8;

      &.active {
        background-color: #fff;
        color: $primary;
      }
    }
  }

  .tab-content {
    position: relative;
    z-index: 1;
  }

  .tab-pane > .card {
    border-radius: 0 0 0.75rem 0.75rem;
  }

  .tabs-title {
    float: right;
    margin: 0;
    line-height: 3.5rem;
  }
}

.custom-checkbox {
  height: 20px;
  width: 20px;
}

.scanner-container {
  display: flex;
  flex-direction: column;
  div {
    margin-bottom: 2rem;
  }
  @media screen and (min-width: 404px) {
    justify-content: start;
    button {
      position: relative;
      top: 38px;
      margin-bottom: 0px;
      align-self: flex-start;
    }
  }
  @media screen and (max-width: 404px) {
    justify-content: center;
    button {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}

.report {
  .btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    background-color: #f2542d !important;
    color: white !important;
    border-color: #f2542d !important;
  }
  .btn-check + .btn:hover {
    background-color: #f2542d !important;
    color: white !important;
    border-color: #f2542d !important;
  }
}