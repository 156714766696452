// TODO - Estilos provisionales

.cms-section {
    padding-left: 15px;
    padding-right: 15px;

    h3 {
        margin-bottom: 2rem;
    }

    .element-title {
        // letra en negrita
        margin-bottom: 0;
        font-weight: bold;
    }
}

.pe-hand {
    cursor: pointer;
}